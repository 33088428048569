
import { computed, defineComponent, onMounted, reactive, Ref, ref, SetupContext, toRefs } from "vue";
import { LeftOutlined, RightOutlined, DownOutlined } from '@ant-design/icons-vue';
import moment, { Moment } from "moment"
import { ArrangementStatusEnum, SearchCalendarResourcePlanningRep } from '@/api-client/client';
import { _Client } from '@/api-client';
import { addTimezoneByDbNameForce } from '@/utils/DateExtensions';
import dayjs ,{Dayjs} from 'dayjs';

export default defineComponent({
  components: { LeftOutlined, RightOutlined, DownOutlined },
  setup(prop: {}, context: SetupContext) {
    const timeMoment = moment
    const arrangementStatusEnum = ArrangementStatusEnum
    let nowYearMonth: string = moment().format('YYYY-MM')

    const firstDayInDayList = ref<Date>(new Date())
    const showDayList = ref<boolean>(false)
    const testDay = ref<Moment>(moment())
    const testDayValue=ref<Dayjs>(dayjs(moment().format('YYYY-MM-DD'),'YYYY-MM-DD'));
    const isActiveDay = ref<Date | null>(new Date())
    const state = reactive<{ calendarResult: SearchCalendarResourcePlanningRep[] }>({
      calendarResult: new Array<SearchCalendarResourcePlanningRep>()
    })

    const allowDayPre = computed(() => {
      if (!(state.calendarResult.length > 0)) {
        return false
      }
      let currentFirtDay = moment(state.calendarResult[0].date).get('date')
      let monthFirstDay = moment(state.calendarResult[0].date).startOf('month').get('date')
      return currentFirtDay !== monthFirstDay
    })

    const allowDayNext = computed(() => {
      if (!(state.calendarResult.length > 0)) {
        return false
      }
      let currentLastDay = moment(state.calendarResult[state.calendarResult.length - 1].date).get('date')
      let monthLastDay = moment(state.calendarResult[state.calendarResult.length - 1].date).endOf('month').get('date')
      return currentLastDay !== monthLastDay
    })

    async function getCalendarResult(testDay: Moment) {
      state.calendarResult = await _Client.resourcePlanningClient.searchCalendar(addTimezoneByDbNameForce(testDay.toString(), 'Etc/GMT-0'))
      firstDayInDayList.value = state.calendarResult[0].date
      showDayList.value = true
    }
    function getDayClassName(dateItem: SearchCalendarResourcePlanningRep): string {
      let dayClassName = ''
      switch (dateItem.arrangementStatus) {
        case ArrangementStatusEnum.NoNeed:
          dayClassName = 'no-planning'
          break
        case ArrangementStatusEnum.NotDone:
          dayClassName = 'pending-planning'
          break
        case ArrangementStatusEnum.Done:
          dayClassName = 'done-planning'
          break
      }
      if (moment(dateItem.date).get('date') === moment(isActiveDay.value).get('date')) {
        dayClassName = dayClassName + ' active-' + dayClassName + '-day'
      }
      return dayClassName
    }
    function getWeekClassName(dateItem: SearchCalendarResourcePlanningRep): string {
      let weekClassName = ''
      if (moment(dateItem.date).get('date') === moment(isActiveDay.value).get('date')) {
        weekClassName = 'active-week'
      }
      return weekClassName
    }
    function isNowDay(date: Date): boolean {
      return moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
    }
    async function changeYearOrMonth(date:any) {
      testDay.value=date;
      // 当前月切换成当前月的时候不需要获取day list,也不需要修改activeDay
      if (!(nowYearMonth === testDay.value.format('YYYY-MM'))) {
        nowYearMonth = testDay.value.format('YYYY-MM')
        isActiveDay.value = null
        if (testDay && testDay.value) {
          testDay.value = <Moment><unknown>testDay.value.format('YYYY-MM')
        }
        await getCalendarResult(testDay.value)
      }
    }
    async function handlePreDay() {
      let nowFirstDay = moment(state.calendarResult[0].date)
      let preFirstDay = moment(nowFirstDay).add(-10, 'days')
      if (nowFirstDay.get('month') !== preFirstDay.get('month')) {
        preFirstDay = nowFirstDay.startOf('month')
      }
      firstDayInDayList.value = preFirstDay.toDate()
      await getCalendarResult(preFirstDay)
    }
    async function handleNextDay() {
      let nowFirstDay = moment(state.calendarResult[0].date)
      let nextFirstDay = moment(nowFirstDay).add(10, 'days')
      if (nowFirstDay.get('month') !== nextFirstDay.get('month')) {
        nextFirstDay = nowFirstDay.endOf('month')
      }
      firstDayInDayList.value = nextFirstDay.toDate()
      await getCalendarResult(nextFirstDay)
    }
    function changeTestDay(testDay: Date) {
      isActiveDay.value = testDay
      context.emit('change', isActiveDay)
    }

    onMounted(async () => {
      await getCalendarResult(testDay.value)
      changeTestDay(new Date())
    })
    return {
      timeMoment,
      showDayList,
      testDay,
      firstDayInDayList,
      allowDayPre,
      allowDayNext,
      isActiveDay,
      testDayValue,
      ...toRefs(state),
      getCalendarResult,
      getDayClassName,
      getWeekClassName,
      isNowDay,
      changeYearOrMonth,
      handlePreDay,
      handleNextDay,
      changeTestDay
    }
  },
});


