import { defineComponent, reactive, ref, toRefs } from 'vue';
import moment from "moment"
import { CreateResourcePlanningCmd,EnumSpeakingType, ResourcePlanningExaminerHubModel, ResourcePlanningSPKBlockModel, SearchResourcePlanningActionOperationLogQuery, SearchResourcePlanningQuery, SearchResourcePlanningRep, ResourcePlanningRegionModel } from '@/api-client/client';
import { _Client } from '@/api-client';
import { IsNullOrUndefined } from '@/utils/common';
import Datepicker from './components/Datepicker/Datepicker.vue'
import FooterBar from '@/components/FooterBar/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import { addTimezoneByDbNameForce } from '@/utils/DateExtensions';
import { message, Modal } from 'ant-design-vue';
import Planning from '@/views/examiner/planning/components/PlanningItem/index.vue';

export default defineComponent({
  components: { Datepicker, FooterBar, Pagination, LogDetail, Planning },
  setup() {
    const timeMoment = moment
    const datepickerRef = ref(null)
    const pageLoading = ref<Boolean>(false)
    
    const datePickerState = reactive<{date:Date | undefined,dateChange: (date:Date)=> void}>({
      date : undefined,
      dateChange :(date)=> {
        datePickerState.date = date
      }
    })

    const examTypeState = reactive<{
      activeKey:string
    }>({
      activeKey:'1'
    })
    
    return { timeMoment, datepickerRef, pageLoading, EnumSpeakingType,examTypeState,datePickerState }
  }
})
