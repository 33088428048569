import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77c6745f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "exam-type-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_planning = _resolveComponent("planning")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_Datepicker, {
          ref: "datepickerRef",
          onChange: _ctx.datePickerState.dateChange
        }, null, 8, ["onChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.examTypeState.activeKey,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.examTypeState.activeKey) = $event)),
          type: "card",
          destroyInactiveTabPane: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "1",
              tab: "All"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_planning, {
                  "exam-date": _ctx.datePickerState.date
                }, null, 8, ["exam-date"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              key: "2",
              tab: "VCS"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_planning, {
                  "exam-date": _ctx.datePickerState.date,
                  "speaking-type": _ctx.EnumSpeakingType.VCS
                }, null, 8, ["exam-date", "speaking-type"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              key: "3",
              tab: "ICS"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_planning, {
                  "exam-date": _ctx.datePickerState.date,
                  "speaking-type": _ctx.EnumSpeakingType.ICS
                }, null, 8, ["exam-date", "speaking-type"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["activeKey"])
      ])
    ])
  ]))
}