import {
  SearchResourcePlanningExaminerHubRep,
  SearchResourcePlanningRegionRep,
  SearchResourcePlanningSPKBlockRep,
  SpeakingSittingBlockEnum,
  EnumSpeakingType
} from "@/api-client/client";
import { ExaminerResourcePlanningChartModel, ExaminerResourcePlanningFormEnum } from "@/api-client/modal";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  SetupContext,
  toRefs,
  defineAsyncComponent,
  PropType
} from "vue";
import { validPositiveInteger } from "@/utils/validate";
import LineChart from '../LineChart/LineChart.vue'
import moment from 'moment'

export default defineComponent({
  components: {
    LineChart : defineAsyncComponent(()=>import('../LineChart/LineChart.vue'))
  },
  props: {
    spkData: { default: () => new SearchResourcePlanningSPKBlockRep() },
    blockLength: { default: 0 },
    speakingType:Object as PropType<EnumSpeakingType>,
  },
  setup: function (props, context: SetupContext) {
    const getPositiveInteger = validPositiveInteger;
    const examinerSetFormRef = ref(null)
    const examinerNeedFormRef = ref(null)
    const activeKey = ref('1')
    const state = reactive({
      lineChartDataOfCd: new ExaminerResourcePlanningChartModel(),
      lineChartDataOfPb: new ExaminerResourcePlanningChartModel()
    })
    getLineChartData()
    const arrangeExaminerCountSum = computed(() => {
      let arrangeExaminerCountSum: number = 0;
      props.spkData.regions!.forEach((regionItem) => {
        regionItem.examinerHubs!.forEach((hubItem) => {
          if(!isNaN(Number(hubItem.examinerNumber))){
            arrangeExaminerCountSum += Number(hubItem.examinerNumber);
          }
        });
      });
      return arrangeExaminerCountSum;
    });
    
    const validateArrangeNum =  async (rule: any, value: any, callback: Function) => {
      if (value > rule.hub.examinerHubCapacity) {
        (examinerNeedFormRef.value as any).validate('needExaminerNumber');
        (examinerSetFormRef.value as any).validate([['regions',rule.regionIndex,'regionNumber']]);
        rule.hub.needNotCheckExaminerNumber = false
        return Promise.reject(" ");
      } else {
        let validResult = validateAllArrangeNum();
        (examinerNeedFormRef.value as any).validate(['needExaminerNumber']);
        (examinerSetFormRef.value as any).validate([['regions',rule.regionIndex,'regionNumber']]);
        rule.hub.needNotCheckExaminerNumber = true
        context.emit("update:spkData", props.spkData);
        return Promise.resolve();
      }
    };

    function validateAllArrangeNum() { 
      let regions = <SearchResourcePlanningRegionRep[]>props.spkData.regions;
      for (let i=0;i<regions.length;i++){
        let region = <SearchResourcePlanningRegionRep>props.spkData.regions[i];

        let examinerHubs = region.examinerHubs?.filter((x:SearchResourcePlanningExaminerHubRep)=>x.examinerNumber > x.examinerHubCapacity && !x.needNotCheckExaminerNumber);
        if (examinerHubs != undefined && examinerHubs.length>0){
          return false;
        }
      }
      return true;
    }
    const validateNeedNum = (rule: any, value: any, callback: Function) => {
      if (Number(value) !== arrangeExaminerCountSum.value) {
        return Promise.reject();
      } else {
        context.emit("update:spkData", props.spkData);
        return Promise.resolve();
      }
    };

    const validateSpkSettingRegionNum = (rule: any, value: any, callback: Function) => {
      let regionIndex = rule.regionIndex
      let examinerHubs = props.spkData.regions![regionIndex].examinerHubs
      let examinerCountSum: number = 0;
      examinerHubs!.forEach((hubItem: any) => {
        if(!isNaN(Number(hubItem.examinerNumber))){
          examinerCountSum += Number(hubItem.examinerNumber);
        }
      });
      if (Number(value) > props.spkData.regions![regionIndex].totalCapacity) {
        return Promise.reject();
      } else if (Number(value) !== examinerCountSum) {
        return Promise.reject();
      } else {
        context.emit("update:spkData", props.spkData);
        return Promise.resolve();
      }
    };

    function getLineChartData() {
      state.lineChartDataOfCd = new ExaminerResourcePlanningChartModel({
        xData: [],
        yData: [],
        moreCount: props.spkData.recommendedExaminerNumber
      })
      props.spkData.cdTimeSlots!.forEach((timeslotItem) => {
        state.lineChartDataOfCd.xData.push(moment(timeslotItem.testTime).format('HH:mm'))
        state.lineChartDataOfCd.yData.push(timeslotItem.registrationNumber)
      })

      state.lineChartDataOfPb = new ExaminerResourcePlanningChartModel({
        xData: [],
        yData: [],
        moreCount: props.spkData.recommendedExaminerNumber
      })
      props.spkData.pbTimeSlots!.forEach((timeslotItem) => {
        state.lineChartDataOfPb.xData.push(moment(timeslotItem.testTime).format('HH:mm'))
        state.lineChartDataOfPb.yData.push(timeslotItem.registrationNumber)
      })

    }

    function clearFormValidate() {
      if (examinerSetFormRef.value !== null) {
        (examinerSetFormRef.value as any).clearValidate();
      }
      if (examinerNeedFormRef.value !== null) {
        (examinerNeedFormRef.value as any).clearValidate();
      }
    }

    return {
      arrangeExaminerCountSum,
      examinerSetFormRef,
      examinerNeedFormRef,
      ...toRefs(state),
      activeKey,
      getPositiveInteger,
      validateNeedNum,
      validateArrangeNum,
      clearFormValidate,
      validateSpkSettingRegionNum
    };
  }
});
