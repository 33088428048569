import {
  defineComponent,
  onMounted,
  computed,
  watch,
  reactive,
  ref,
  toRefs,
} from "vue";
import type { PropType } from "vue";
import moment from "moment";
import {
  SearchResourcePlanningRep,
  EnumSpeakingType,
  SearchResourcePlanningQuery,
  CreateResourcePlanningCmd,
  ResourcePlanningExaminerHubModel,
  ModifyResourcePlanningCmd,
  SearchResourcePlanningActionOperationLogQuery,
  ResourcePlanningRegionModel,
  ResourcePlanningSPKBlockModel,
} from "@/api-client/client";
import {
  ExaminerResourcePlanningFormEnum,
  ExaminerResourcePlanningFormModel,
  LogDetailModel,
} from "@/api-client/modal";
import SetExaminerNumber from "@/views/examiner/planning/components/SetExaminerNumber/SetExaminerNumber.vue";
import { _Client } from "@/api-client";
import { addTimezoneByDbNameForce } from "@/utils/DateExtensions";
import LogDetail from "@/components/LogDetail/index.vue";
import { message, Modal } from "ant-design-vue";
import { IsNullOrUndefined } from "@/utils/common";

type AsyncFunction = () => Promise<void>;

export default defineComponent({
  components: { SetExaminerNumber, LogDetail },
  props: {
    examDate: Date,
    speakingType: Object as PropType<EnumSpeakingType>,
  },
  setup(props) {
    const timeMoment = moment;
    let needValidResult: ExaminerResourcePlanningFormModel = {
      formName: ExaminerResourcePlanningFormEnum.ExaminerNeedForm,
      validResult: true,
    };
    let arrangeValidResult: ExaminerResourcePlanningFormModel = {
      formName: ExaminerResourcePlanningFormEnum.ExaminerSetForm,
      validResult: true,
    };

    const planFormRef =  ref()
    
    onMounted(async () => {
      await state.getData();
    });


    const state = reactive<{
      data: SearchResourcePlanningRep;
      dataCopy?: SearchResourcePlanningRep;
      loading: boolean;
      getData: AsyncFunction;
    }>({
      data: new SearchResourcePlanningRep(),
      loading: true,

      getData: async () => {
        state.loading = true;
        const date = props.examDate
          ? addTimezoneByDbNameForce(props.examDate!, "Etc/GMT-0")
          : undefined;
        try {
          if (!date) {
            return;
          }
          const data = await _Client.resourcePlanningClient.search(
            new SearchResourcePlanningQuery({ testDay: date,speakingType : props.speakingType })
          );
          state.data = data;
          state.dataCopy = JSON.parse(JSON.stringify(data));
        } finally {
          state.loading = false;
        }
      },
    });

    watch(
      () => props.examDate,
      (value, oldValue) => {
        state.getData();
      }
    );

    const hasSPKBlockData = computed(() => {
      return state.data?.spkBlocks && state.data.spkBlocks.length > 0;
    });

    const blockSpan = computed(() => {
      let result = { xs: 24, sm: 12, lg: 8 };
      if (hasSPKBlockData) {
        result =
          state.data!.spkBlocks!.length !== 3
            ? { xs: 24, sm: 12, lg: 12 }
            : { xs: 24, sm: 12, lg: 8 };
      }
      return result;
    });

    const logOperation = () => {
      const logDetailRef = ref(null);
      const logBtnLoading = ref<boolean>(false);

      const logState = reactive<{
        logSearchParames: SearchResourcePlanningActionOperationLogQuery;
        logDetail: LogDetailModel;
      }>({
        logSearchParames: new SearchResourcePlanningActionOperationLogQuery(),
        logDetail: new LogDetailModel(),
      });
      async function showLogDialog() {
        logBtnLoading.value = true;
        logState.logSearchParames = {
          ...logState.logSearchParames,
          pageIndex: 1,
          testDay: addTimezoneByDbNameForce(props.examDate!, "Etc/GMT-0"),
          speakingType : props.speakingType!
        };
        await getLogList();
      }
      async function getLogList() {
        try {
          let logDetail =
            await _Client.actionOperationLogClient.resourcePlanningQuery(
              logState.logSearchParames
            );
          logState.logDetail = new LogDetailModel(logDetail);
        } finally {
          logBtnLoading.value = false;
          (logDetailRef.value as any).showLogModal = true;
        }
      }

      return {
        logDetailRef,
        logBtnLoading,
        ...toRefs(logState),
        showLogDialog,
        getLogList,
      };
    };

    function setValidResult(formName: string, validResult: boolean) {
      if (formName === arrangeValidResult.formName) {
        arrangeValidResult.validResult = validResult;
      } else if (formName === needValidResult.formName) {
        needValidResult.validResult = validResult;
      }
    }

    function reset() {
      state.data = JSON.parse(JSON.stringify(state.dataCopy))
      planFormRef.value.forEach((ref:any)=>{
        ref.clearFormValidate()
      })
    }

    const submitState = reactive<{
      loading: boolean;
      validate: Function;
      isValid: boolean;
      submit: AsyncFunction;
    }>({
      loading: false,
      isValid: false,
      validate: () => {
        submitState.isValid = false;
        state.data.spkBlocks?.forEach((block) => {
          //验证block number和block下hub填写的总数是否相等
          (block as any).blockExaminerNumberValid = true;
          let arrangeBlockExaminerCountSum: number = 0;
          block.regions!.forEach((regionItem) => {
            //验证region输入的数量和region能容纳的数量对比
            (regionItem as any).regionNumberValid = true;
            //验证region输入的数量和region下hub填写的总数是否相等
            (regionItem as any).regionNumberEqual = true;
            if (regionItem.regionNumber! > regionItem.totalCapacity) {
              (regionItem as any).regionNumberValid = false;
            }
            let arrangeRegionExaminerCountSum = 0;
            regionItem.examinerHubs!.forEach((hubItem) => {
              if (!isNaN(Number(hubItem.examinerNumber))) {
                arrangeBlockExaminerCountSum += Number(hubItem.examinerNumber);
                arrangeRegionExaminerCountSum += Number(hubItem.examinerNumber);
              }
            });
            if (
              Number(regionItem.regionNumber) !== arrangeRegionExaminerCountSum
            ) {
              (regionItem as any).regionNumberEqual = false;
            }
          });
          if (
            Number(block.needExaminerNumber) !== arrangeBlockExaminerCountSum
          ) {
            (block as any).blockExaminerNumberValid = false;
          }
        });

        let blockExaminerNumberInvalid = 0;
        let regionNumberInvalid = 0;
        let regionNumberEqualInvalid = 0;

        if (
          state.data.spkBlocks?.filter(
            (s) => !(s as any).blockExaminerNumberValid
          ).length! > 0
        ) {
          Modal.warning({
            content:
              "The number highlighted is not equal to the total number of needed examiners",
            okText: "Confirm",
          });
          return;
        }
        state.data.spkBlocks?.forEach((block) => {
          //每个region的examiner number和hub的统计examiner number之和是否相等
          if (
            block.regions?.filter((r) => !(r as any).regionNumberEqual)
              .length! > 0
          ) {
            blockExaminerNumberInvalid++;
          }
          //每个region的examiner number是否大于region的Capacity
          if (
            block.regions?.filter((r) => !(r as any).regionNumberValid)
              .length! > 0
          ) {
            console.log("c");
            regionNumberInvalid++;
          }

          block.regions?.forEach((r) => {
            if (
              r.examinerHubs?.filter((s) => !s.needNotCheckExaminerNumber)
                .length! > 0
            ) {
              regionNumberEqualInvalid++;
            }
          });
        });
        if (blockExaminerNumberInvalid > 0) {
          Modal.warning({
            content:
              "The number highlighted is not equal to the total number of needed examiners",
            okText: "Confirm",
          });
          return;
        }
        if (regionNumberInvalid > 0) {
          const modal = Modal.confirm({
            content:
              "The number highlighted exceeds the capacity number of enabled hubs， please check and confirm your operation?",
            okText: "Confirm",
            cancelText: "Cancel",
            onOk: async () => {
              submitState.isValid = true;
              await submitState.submit();
              modal.destroy();
            },
          });
          return;
        }

        if (regionNumberEqualInvalid > 0) {
          const modal = Modal.confirm({
            content:
              "The number highlighted exceeds the capacity number of enabled hubs， please check and confirm your operation?",
            okText: "Confirm",
            cancelText: "Cancel",
            onOk: async () => {
              submitState.isValid = true;
              await submitState.submit();
              modal.destroy();
            },
          });
          return;
        }

        submitState.isValid = true;
      },

      submit: async () => {
        submitState.validate();
        if (!submitState.isValid) return;

        submitState.loading = true;

        let spkBlocks: ResourcePlanningSPKBlockModel[] = [];
        spkBlocks = state.data.spkBlocks!.map((spkBlock) => {
          let submitItem: ResourcePlanningSPKBlockModel =
            new ResourcePlanningSPKBlockModel();
          submitItem.examinerHubs = [];
          submitItem.regionPlans = [];
          submitItem.speakingSitting = spkBlock.speakingSitting;
          submitItem.needExaminerHubNumber = Number(
            spkBlock.needExaminerNumber
          );
          spkBlock.regions!.forEach((regionItem) => {
            submitItem.regionPlans!.push(
              new ResourcePlanningRegionModel({
                regionId: regionItem.regionId,
                examinerNumber: Number(regionItem.regionNumber!),
                regionName: regionItem.regionName!,
              })
            );
            regionItem.examinerHubs!.forEach((hubItem) => {
              submitItem.examinerHubs!.push(
                new ResourcePlanningExaminerHubModel({
                  examinerHubId: hubItem.examinerHubId,
                  examinerHubName: hubItem.examinerHubName!,
                  examinerHubNumber: Number(hubItem.examinerNumber),
                  regionId: regionItem.regionId,
                })
              );
            });
          });
          return submitItem;
        });
        
        const submitModel = new CreateResourcePlanningCmd({
          testDay: addTimezoneByDbNameForce(props.examDate!, "Etc/GMT-0"),
          spkBlocks,speakingType :props.speakingType!
        });
        console.log(props.speakingType,submitModel)
        try {
          IsNullOrUndefined(state.dataCopy!.resourcePlanningId)
            ? await _Client.resourcePlanningClient.create(new CreateResourcePlanningCmd({
              testDay: addTimezoneByDbNameForce(props.examDate!, "Etc/GMT-0"),
              spkBlocks,speakingType :props.speakingType!
            }))
            : await _Client.resourcePlanningClient.modify(new ModifyResourcePlanningCmd({
              resourcePlanningId : state.data.resourcePlanningId!,
              spkBlocks
            }));

          message.success("success");
          
          reset()
          state.getData()
        } finally {
          submitState.loading = false;
        }
      },
    });

    return {
      timeMoment,
      hasSPKBlockData,
      blockSpan,
      setValidResult,
      state,
      ...logOperation(),
      reset,
      submitState,
      planFormRef
    };
  },
});
